<template>
  <v-container fluid id="container" class="d-flex align-center justify-center">
    <v-row class="d-flex align-center">
      <v-col offset="1" offset-md="2" cols="10" md="8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" id="observer">
          <v-form role="registerForm" @submit.prevent="handleSubmit(saveUser)" @reset="resetForm" id="form">
            <v-row class="text-h4 font-weight-bold mb-8" justify="center">
              {{ $t("signUpHeader") }}
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("username") }}
              </v-label>
              <v-text-field name="username" :rules="usernameValidation" v-model="user.handle" filled id="username" :placeholder="$t('username')" />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("firstName") }}
              </v-label>
              <v-text-field name="First name" :rules="firstNameValidation" v-model="user.firstName" filled id="firstname" :placeholder="$t('name')" />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("lastName") }}
              </v-label>
              <v-text-field name="Last name" :rules="lastNameValidation" v-model="user.lastName" id="lastname" filled :placeholder="$t('surname')" />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("email") }}
              </v-label>
              <v-text-field filled name="email" :rules="emailValidation" v-model="user.email" id="email" :placeholder="$t('email')" />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("password") }}
              </v-label>
              <v-text-field type="password" name="password" :rules="passwordValidation" v-model="user.password" id="password" filled :placeholder="$t('password')" />
            </v-row>
            <v-row class="my-8">
              <v-btn block color="primary" type="submit" id="signup">
                {{ $t("signUp") }}
              </v-btn>
            </v-row>
            <v-row class="text-subtitle-1" justify="center">
              {{ $t("alreadyHaveAccount") }}
              <router-link to="/login" class="text-decoration-none font-weight-bold fs-14 ml-6" id="sign-up-link">
                {{ $t("logintoyouraccount") }}
              </router-link>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeAuthService from "@/services/api/auth";
import makeOrgService from "@/services/api/org";
import { createNamespacedHelpers } from "vuex";
import { handleDuplicateMixin } from "@/mixins/handleDuplicate.js";
import { emailValidationRules, passwordValidationRules } from "@/utils/validation";
const { mapMutations, mapActions } = createNamespacedHelpers("user");
import { showAuthErrorToast } from '@/utils/toast';

export default {
  data()
  {
    return {
      user: {
        handle: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      visiblePassword: false,
      invite: null,
    };
  },
  watch: {
    'user.handle': {
      handler: 'usernameInUse',
      immediate: true,
    },
  },
  computed: {
    emailValidation()
    {
      return emailValidationRules(this);
    },
    firstNameValidation()
    {
      return [
        (v) => !!v || this.$t("firstNameRequired"),
        (v) => (v && v.length >= 2) || this.$t("min2Chars"),
      ];
    },
    lastNameValidation()
    {
      return [
        (v) => !!v || this.$t("lastNameRequired"),
        (v) => (v && v.length >= 2) || this.$t("min2Chars"),
      ];
    },
    passwordValidation()
    {
      return passwordValidationRules(this);
    },
    usernameValidation()
    {
      const defaultRules = [
        (v) => !!v || this.$t('usernameRequired'),
        (v) => /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(v) || this.$t('invalidUsername'),
      ];
      if (this.handleError) {
        return [
          ...defaultRules,
          value => this.handleError,
        ]
      }
      return defaultRules
    },
    disableEmail()
    {
      return !!this.invite;
    },
  },
  mounted()
  {
    this.checkForInvite(this.$route.query);
  },
  mixins: [handleDuplicateMixin],
  methods: {
    ...mapMutations(["setUser"]),
    ...mapActions(["initSession"]),
    saveUser()
    {
      const authService = new makeAuthService(this.$api);
      authService
        .register(this.user)
        .then(async (response) =>
        {
          const user = response.data.user;
          this.initSession({
            user,
            currentAccount: {
              handle: user.handle,
              type: "user",
              name: `${user.firstName} ${user.lastName}`,
              roleName: "owner"
            }
          })

          if (this.invite) {
            return this.$router.push({ name: 'GetInvite', params: this.invite });
          }

          this.$router.push('/setup');
        })
        .catch((error) =>
        {
          let errorMessage;
          if (Array.isArray(error.response.data.error)) {
            errorMessage = error.response.data.error.join(' ');
          } else {
            errorMessage = error.response.data.error;
          }
          showAuthErrorToast(this.$swal, errorMessage);
        });
    },
    resetForm()
    {
      this.user = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      };
      this.$nextTick(() =>
      {
        this.$refs.observer.reset();
      });
    },
    checkForInvite(params)
    {
      if (!params?.token || !params.org) return;

      const invite = { handle: params.org, token: params.token };
      makeOrgService(this.$api)
        .validateInvite(invite)
        .then((response) =>
        {
          this.invite = { ...invite };
          this.user.email = response.data.email;
        })
        .catch((error) =>
        {
          // error validating invite
          return;
        });
    },
  },
};
</script>
